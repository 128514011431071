const initCursor = () => {
    const ua = navigator.userAgent;
    let deviceType = true
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        deviceType = false;
    } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        deviceType = false;
    }
    if(deviceType) {
        let innerCursor = document.querySelector('.inner_cursor');
        let outerCursor = document.querySelector('.outer_cursor');
        document.addEventListener('mousemove', moveCursor);
        function moveCursor(e){
            let x = e.clientX;
            let y = e.clientY;

            innerCursor.style.left = `${x}px`
            innerCursor.style.top = `${y}px`
            outerCursor.style.left = `${x}px`
            outerCursor.style.top = `${y}px`
        }

        let links = Array.from(document.querySelectorAll("a"));
        let buttons = Array.from(document.querySelectorAll("button"));
        
        links.forEach(link => {
            link.addEventListener('mouseover', ()=>{
                innerCursor.classList.add('grow')
            });
            link.addEventListener('mouseleave', ()=>{
                innerCursor.classList.remove('grow')
            });
        });

        buttons.forEach(button => {
            button.addEventListener('mouseover', ()=>{
                innerCursor.classList.add('grow')
            });
            button.addEventListener('mouseleave', ()=>{
                innerCursor.classList.remove('grow')
            });
        });
    }
};

export { initCursor };
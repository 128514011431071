const initComputerMove = () => {
    const computer = document.getElementById('welcome_computer');
    const button = document.querySelector('.button.button-animation'); // Assurez-vous que cela cible bien votre bouton

    computer.addEventListener('mousemove', function(event) {
        const element = event.target;

        if (!element.dataset.initiated) {
            element.style.transition = 'none';

            setTimeout(() => {
                element.style.transition = 'transform 0.1s ease';
                element.dataset.initiated = true;
            }, 100);
        } else {
            const { left, top, width, height } = element.getBoundingClientRect();
            const x = (event.clientX - left) / width;
            const y = (event.clientY - top) / height;

            const rotateX = (y - 0.5) * 30;
            const rotateY = (x - 0.5) * -30;

            element.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
        }
    });

    computer.addEventListener('mouseleave', function(event) {
        const element = event.target;
        element.style.transition = 'transform 0.3s ease';
        element.style.transform = 'rotateX(0) rotateY(0)';
        delete element.dataset.initiated;
    });

    if (button) {
        button.addEventListener('animationend', function() {
            this.classList.remove('button-animation');
        });
    }
};

export { initComputerMove };
